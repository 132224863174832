import React from 'react';
import Link from 'components/ui/link';
import Article from 'components/help/helpArticle';
import Code from 'components/help/codeSnippet';

const Content = () => {
  return (
    <div>
      <p>
        Opting out from LiveSession will put a{' '}
        <Link
          href="https://en.wikipedia.org/wiki/HTTP_cookie"
          target="_blank"
          rel="noopener noreferrer"
        >
          cookie
        </Link>{' '}
        on your site that tells our script to be turned off.
      </p>
      <div className="alert alert-primary mb-5 mt-5" role="alert">
        You need to allow your users to turn off session recordings. The instructions should be
        included in your privacy policy.
      </div>
      <p>From a technical perspective, there are two ways to do it:</p>
      <ul>
        <li>
          <p>
            <strong>Using script API</strong>
          </p>
          <p>
            The user can be excluded from recordings with the optOut function:{' '}
            <Code inline>__ls(&quot;optOut&quot;)</Code>
          </p>
        </li>
        <li>
          <p>
            <strong>Using links</strong>
          </p>
          <p>
            You can allow your users to opt-out from LiveSession by clicking a link. It will add{' '}
            <Code inline>__ls_optout=1</Code> to your website URL query-string parameters. The URL
            will look like this: <Code inline>www.your-site.com?__ls_optout=1</Code>
          </p>
        </li>
      </ul>
      <p>
        You can also disable LiveSession for just one session. This can be done with this method:{' '}
        <Code inline> __ls(&quot;off&quot;)</Code>
      </p>
    </div>
  );
};

const related = [
  {
    link: '/help/how-to-install-livesession-script',
    title: 'How to install LiveSession script?',
    description: 'Check how can you install LiveSession on any website.',
  },
];

export const frontmatter = {
  metaTitle: 'Opt-out cookie',
  metaDescription: 'How to opt-out from LiveSession tracking',
  canonical: '/help/opt-out-cookie/',
};

const Wrapped = Article(Content);
export default () => (
  <Wrapped related={related} section="Privacy" title={frontmatter.metaTitle} {...frontmatter} />
);
